.flex {
    display: flex;
}

.card {
    height: 320px;
    border-radius: 0px 0px 0px 0px;
    border: none;
    overflow: hidden;
}

img {
    height: 320px;
}

.intro {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    position: absolute;
    color: white;
    top: 150px;

}

h1 {
    margin: 10px;
    font-size: 1.3rem;
    padding: 10px;
    font-weight: 700;
    text-align: center;
}

h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 25px;
    visibility: hidden;
    border: none;
    opacity: 0;
}

span {
    font-weight: bold;
}

.card:hover {
    cursor: pointer;
}

.card:hover .intro {
    height: 300px;
    top: 1px;
    transition: .60s ease-in-out;
}

.card:hover h4 {
    visibility: visible;
    opacity: 1;
}

.card:hover h1 {
    margin: 70px auto 20px;
    height: 3.75rem;
    width: 85%;
    border: .3125rem solid #11b6e4;
}