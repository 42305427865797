
.imageONLOCATION{
    width: 100%;
    height: 280px;
    filter: grayscale(0) contrast(0.6);
}

.imageONLOCATION:hover{
    filter: grayscale(0) contrast(1.6) ;
    cursor: pointer;
}