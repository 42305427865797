
.contact-number{
    color: white;
    font-size: .955rem;
}

.contact-number:hover{
    color: #11b6e4;
    cursor: pointer;
}

.SocialIcon {
    padding: 10px;
    border: 2px solid #11b6e4;
    background-color: #11b6e4;
    color: #fff;
}

.SocialIcon:hover {
    color: #11b6e4;
    background-color: white;
    border: 2px solid #11b6e4;
    border-radius: 50%;

}