


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.image3D{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.5);
}

.image3D:hover{
    filter: grayscale(0) contrast(1.4) ;
}