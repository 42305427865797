


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imageTOOLMAKING{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.8);
}

.imageTOOLMAKING:hover{
    filter: grayscale(0) contrast(1.4) ;
}