

.imageHVC{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(0.8);
}

.imageHVC:hover{
    filter: grayscale(0) contrast(1) ;
}

