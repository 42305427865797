


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imageMANUFACTURING{
    width: 100%;
    height: 330px;
    filter: grayscale(0) contrast(.5);
}

.imageMANUFACTURING:hover{
    filter: grayscale(0) contrast(1.1) ;
}