


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imagePLASTICMODULING{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.5);
}

.imagePLASTICMODULING:hover{
    filter: grayscale(0) contrast(1.3) ;
}