.sticky-container {
    padding: 0px;
    margin: 0px;
    right: -125px;
    top: 300px;
    width: 210px;
    z-index: 999;
}



.sticky li {
    list-style-type: none;
    background-color: #11b6e4;
    color: #fff;
    height: 53px;
    padding: 0px;
    margin: 0 0 1px;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.sticky li:hover {
    list-style-type: none;
    background-color: #11b6e4;
    color: #fff;
    /* transform: translate(-100px); */
    height: 53px;
    padding: 0px;
    margin: 0 0 1px;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.sticky li i {
    /* float: left; */
    margin: 15px;
    justify-content: center;
    margin-right: 20px;
}

.sticky li p {
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;
    /* float: left; */
}



.sticky-container {
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: -125px;
    top: 300px;
    width: 210px;
    z-index: 99999999999999999999999999999;
}


.sticky li {
    list-style-type: none;
    background-color: #11b6e4;
    color: #fff;
    height: 53px;
    padding: 0px;
    margin: 0 0 1px;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.sticky li:hover {
    list-style-type: none;
    background-color: #11b6e4;
    color: #fff;
    /* transform: translate(-100px); */
    height: 53px;
    padding: 0px;
    margin: 0 0 1px;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.sticky li i {
    /* float: left; */
    justify-content: center;
    margin: 15px;
    font-size: 30px;
    margin-right: 20px;
}

.sticky li p {
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;
    /* float: left; */
}


@media only screen and (max-width: 990px) {
    .sticky-container {
        padding: 0px;
        margin: 0px;
        position: fixed;
        right: -85px;
        top: 235px;
        width: 180px;
        z-index: 99999999999999999999999999999;
    }

}