




.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imageSMW{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.7);
}

.imageSMW:hover{
    filter: grayscale(0) contrast(1) ;
}