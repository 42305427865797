

.imageWIRE{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.5);
}

.imageWIRE:hover{
    filter: grayscale(0) contrast(1) ;
}