


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imagePLASTICMACHINING{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.8);
}

.imagePLASTICMACHINING:hover{
    filter: grayscale(0) contrast(1.6) ;
}