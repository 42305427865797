

.FONT{
    font-family: 'Josefin Sans', sans-serif;
    font-size: .875rem;
    font-weight: 800;
}


.navbar-nav{
    display: flex;
    width: 100%;
    text-transform: uppercase;
    
}

.navbar-nav .nav-link {
    color: #fff;
    font-size: .8411rem;
}

.dropend .dropdown-toggle {
    color: #fff;
    font-weight: 400;
    border-bottom: .0625rem solid rgba(255,255,255,.2);
}


.dropdown-item{
    color: #fff;
    padding: 10px;
    border-bottom: .0625rem solid rgba(255,255,255,.2);
}

.dropdown-item:hover {
    background-color: black;
    color: #11b6e4; 
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    padding: 0px;
    font-size: .75rem;
    border-top: 0.3125rem solid #11b6e4;  
    background-color: black;
    border-radius: 0px ;
    border-bottom: .0625rem solid rgba(255,255,255,.2);
}

@media screen and (min-width: 1200px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }
}


@media screen and (min-width: 1000px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }
}