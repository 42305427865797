
.imageENG{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(1.3);
    /* filter: opacity(.9); */
   
}

.imageENG:hover{
    filter: grayscale(1) contrast(1) ;
    filter: opacity(.8);
    opacity: 1;
}


.VIDEOheight {
    height: 76vh;
}

.HEADING{
    font-size: 5rem;
    line-height: 4.75rem;
    font-weight: 800;
}

.FONT {
    font-family: 'Josefin Sans', sans-serif;
    font-size: .875rem;
    font-weight: 800;
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}


.ReadMoreButton {
    background-color: transparent;
    border: .3125rem solid #11b6e4;
    border-image: linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 4.0625rem,#11b6e4 3.75rem) 3;
    transition: all .25s ease-in-out;
    height: 3.75rem;
    min-width: 10rem;
    color: #11b6e4;
}

.ReadMoreButton:hover {
    background-color: transparent;
    border: .3125rem solid #11b6e4;
    border-image: linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 4.0625rem,#11b6e4 3.75rem) 3;
    height: 3.75rem;
    min-width: 10rem;
    color: #fff;
}

.LAST1IMG{
    height: 85vh;
}

.ROW{
    display: flex;
    justify-content: end;
}

.ROW1{
    text-align: end;
}


    

.CLIENTFOCUS{
    color: white;
}  

.CLIENTFOCUS:hover{
    color: #11b6e4;
    cursor: pointer;
}



@media only screen and (max-width: 1200px) {

    .VIDEOheight {
        height: 100%;
    } 
    .HEADING{
        font-size: 1.5rem;
        line-height: 1;
    }
    .ReadMoreButton{
        width: 100%;
    }

    .LAST1IMG{
        height: 100%;
    }

    .ROW{
        display: flex;
        justify-content: center;  
    }
    
    .ROW1{
        text-align: center;   
    }
}

