


.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.imageCONSTRUCTIONS{
    width: 100%;
    height: 350px;
    filter: grayscale(0) contrast(.6);
}

.imageCONSTRUCTIONS:hover{
    filter: grayscale(0) contrast(1.4) ;
}