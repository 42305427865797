

.imageCOMPONENTS{
    width: 100%;
    height: 280px;
    filter: grayscale(0) contrast(0.5);
}

.imageCOMPONENTS:hover{
    filter: grayscale(0) contrast(1.3) ;
    cursor: pointer;
}