
.Font_Alegreya {
    font-family: 'Alegreya', serif;
}

.emailHover1{
    color: #11b6e4;
    text-decoration: none;
}

.emailHover1:hover{
    text-decoration: none;
    color: #11b6e4;
}

.IconHoverEffect1{
    background-color: #A2D25E;
    border-radius: 50%;
   
}
.IconHoverEffect1:hover{
    cursor: pointer;
}

.IconHoverEffect2{
    background-color: #5CD0BC;
    border-radius: 50%;
   
}
.IconHoverEffect2:hover{
    cursor: pointer;
}

.IconHoverEffect3{
    background-color: #f05c5b;
    border-radius: 50%;  
}
.IconHoverEffect3:hover{
    cursor: pointer;
}

.ColHover{
    transition: transform 1.1s;
}

.ColHover:hover{
    transform: scale(1.2);
    overflow: hidden;
}


.SendButton{
    background-color: #091f2c;
    color: #11b6e4;
    height: 55px;
    width: 30%;
    border: .3125rem solid #11b6e4;
    border-image: linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 4.0625rem,#11b6e4 3.75rem) 3;
}

.SendButton:hover{
    color: #fff;
    border-image: linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 4.0625rem,#11b6e4 3.75rem) 3;
}