.FONTFAMILYFOOTER {
    font-family: 'Alegreya', serif;
}

.SocialIcon {
    background-color: #11b6e4;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
}

.FOOTERHOVER {
    color: #fff;
    font-size: 15px;
}

.FOOTERHOVER:hover {
    color: #11b6e4;
    cursor: pointer;
}

/* footer animation css */

.footer-animation:hover {
    transform: translateX(6px);
    transition: all ease-in .3s;
    cursor: initial;
}