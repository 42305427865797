 /* 4 imge overlay css */


 .flex {
     display: flex;
 }

 .card {
     height: 320px;
     border-radius: 0px 0px 0px 0px;
     border: none;
     overflow: hidden;
 }

 img {
     height: 320px;
 }

 .intro {
     width: 100%;
     padding: 6px;
     box-sizing: border-box;
     position: absolute;
     color: white;
     top: 150px;

 }

 h1 {
     margin: 10px;
     font-size: 1rem;
     padding: 10px;
     text-align: center;
 }

 h6 {
     margin: 50px;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     text-align: center;
     visibility: hidden;
     border: none;
     opacity: 0;
 }

 span {
     font-weight: bold;
 }

 .card:hover {
     cursor: pointer;
 }

 .card:hover .intro {
     height: 300px;
     top: 1px;
     transition: .60s ease-in-out;
 }

 .card:hover h6 {
     visibility: visible;
     opacity: 1;
     margin-top: 10px;
 }

 .card:hover h1 {
     margin: 70px auto 20px;
     height: 3.75rem;
     width: 60%;
     border: .3125rem solid #11b6e4;
 }


 /* 4 img overlay css en */


 .VIDEOheight {
     height: 76vh;
 }

 .HEADING {
     font-size: 5rem;
     line-height: 4.75rem;
     font-weight: 800;
 }

 .FONT {
     font-family: 'Josefin Sans', sans-serif;
     font-size: .875rem;
     font-weight: 800;
 }

 .FontFamilyMontSerret {
     font-family: 'Montserrat', sans-serif;
 }


 .ReadMoreButton {
     background-color: transparent;
     border: .3125rem solid #11b6e4;
     border-image: linear-gradient(45deg, #11b6e4, #11b6e4 3.75rem, transparent 3.75rem, transparent 4.0625rem, #11b6e4 3.75rem) 3;
     transition: all .25s ease-in-out;
     height: 3.75rem;
     min-width: 10rem;
     color: #11b6e4;
 }

 .ReadMoreButton:hover {
     background-color: transparent;
     border: .3125rem solid #11b6e4;
     border-image: linear-gradient(45deg, #11b6e4, #11b6e4 3.75rem, transparent 3.75rem, transparent 4.0625rem, #11b6e4 3.75rem) 3;
     height: 3.75rem;
     min-width: 10rem;
     color: #fff;
 }

 .LAST1IMG {
     height: 85vh;
 }

 .ROW {
     display: flex;
     justify-content: end;
 }

 .ROW1 {
     text-align: end;
 }




 .CLIENTFOCUS {
     color: white;
 }

 .CLIENTFOCUS:hover {
     color: #11b6e4;
     cursor: pointer;
 }



 @media only screen and (max-width: 1200px) {

     .VIDEOheight {
         height: 100%;
     }

     .HEADING {
         font-size: 1.5rem;
         line-height: 1;
     }

     .ReadMoreButton {
         width: 100%;
     }

     .LAST1IMG {
         height: 100%;
     }

     .ROW {
         display: flex;
         justify-content: center;
     }

     .ROW1 {
         text-align: center;
     }
 }